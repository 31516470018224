import { Actions, Effect, ofType } from '@ngrx/effects';
import * as AppActions from './app.actions'
import { switchMap, map } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { VolunteerDetailsText } from '../models/volunteer-details-Text.model';

@Injectable()
export class volunteerDetailsTextEffects {
    @Effect()
    fetchVolunteerDetailsText = this.actions$.pipe(
        ofType(AppActions.FETCH_VOLUNTEER_DETAILS_TEXT),
        switchMap(() => {
            return this.httpClient.get<VolunteerDetailsText[]>(environment.organizationApiUrl + "ProjectPublic/GetVolunteerDetailsText");
        }),
        map(volunteerDetailsText => {
            return volunteerDetailsText.map(item => {
                return item;
            })
        }),
        map(volunteerDetailsText => {
            return new AppActions.SetVolunteerDetailsText(volunteerDetailsText);
        })
    )

    constructor(private actions$: Actions, private httpClient: HttpClient) { }
}