import { Actions, Effect, ofType } from '@ngrx/effects';
import * as AppActions from './app.actions'
import { switchMap, map } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ProjectField } from '../models/project-field.model';

@Injectable()
export class projectFieldsEffects {
    @Effect()
    fetchProjectFields = this.actions$.pipe(
        ofType(AppActions.FETCH_PROJECT_FIELDS),
        switchMap(() => {
            return this.httpClient.get<ProjectField[]>(environment.organizationApiUrl + "ProjectField/get");
        }),
        map(projectFields => {
            return projectFields.map(expenseType => {
                return expenseType;
            })
        }),
        map(projectFields => {
            return new AppActions.SetProjectFields(projectFields);
        })
    )

    constructor(private actions$: Actions, private httpClient: HttpClient) { }
}