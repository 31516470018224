import { Actions, Effect, ofType } from '@ngrx/effects';
import * as AppActions from './app.actions'
import { switchMap, map } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { OrganizationField } from '../models/organization-field.model';

@Injectable()
export class organizationFieldsEffects {
    @Effect()
    fetchOrganizationFields = this.actions$.pipe(
        ofType(AppActions.FETCH_ORGANIZATION_FIELDS),
        switchMap(() => {
            return this.httpClient.get<OrganizationField[]>(environment.organizationApiUrl + "OrganizationField/get");
        }),
        map(organizationFields => {
            return organizationFields.map(expenseType => {
                return expenseType;
            })
        }),
        map(organizationFields => {
            return new AppActions.SetOrganizationFields(organizationFields);
        })
    )

    constructor(private actions$: Actions, private httpClient: HttpClient) { }
}