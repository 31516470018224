import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { Project } from "app/shared/models/project.model";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router/router";
import { ActivatedRoute, Router } from "@angular/router";
import { KeyValue } from "@angular/common";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService implements CanActivate {

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return false;
    }

    constructor(private route: ActivatedRoute) {
    }
  

  plainMenu: IMenuItem[] = [
    {
      name: "דף ארגון",
      type: "link",
      icon: "dashboard",
          state: "organization/form"
      },
      {
          name: "הוספת פרויקט",
          type: "link",
          icon: "add_to_photos",
          state: "project/form"
      },
    {
      name: "DOC",
      type: "extLink",
      tooltip: "Documentation",
      icon: "library_books",
      state: "http://demos.ui-lib.com/egret-doc/"
    }
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "Frequently Accessed";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.plainMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

    public currentProjectId = new Subject<string>();
    public currentProjectId$ = this.currentProjectId.asObservable();
    public projectsKeyValue : KeyValue<string,string>[];
    public projectsMenu = new BehaviorSubject<KeyValue<string, string>[]>([]);
    public projectsMenu$ = this.projectsMenu.asObservable();

    public currentProjectIndex = 0;
    //public currentProjectId = "";
    projectsIds: string[];

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange() {
    this.menuItems.next(this.plainMenu);
  }

    hideAddProjectMenuItem() {
        if (this.plainMenu.length > 1) {
            this.plainMenu = [
                {
                    name: "דף ארגון",
                    type: "link",
                    icon: "dashboard",
                    state: "organization/form"
                }];
            this.publishNavigationChange();
        }
    }

    setProjectIds(projects: Project[]) {
        this.projectsIds = [];
        this.projectsKeyValue = [];
        for (var i = 0; i < projects.length; i++) {
            this.projectsIds.push(projects[i].encryptedID);
            this.projectsKeyValue.push({ key: projects[i].encryptedID, value: projects[i].title });
        }
        this.projectsMenu.next(this.projectsKeyValue);        
    }

    addProjectId(encryptedID: string) {
        if (!this.projectsIds) {
            this.projectsIds = [];
        }
        this.projectsIds.push(encryptedID);        
    }

    setCurrentIndexByProjectId(projectId : string){
        this.currentProjectId.next(projectId);
        for (var i = 0; i < this.projectsIds.length; i++) {
            if (projectId == this.projectsIds[i]) {
                this.currentProjectIndex = i;
            }
        }
    }

    clearCurrentIndex() {
        this.currentProjectId.next('');
        this.currentProjectIndex = 0;
    }

    addProjectMenu(projectItem: KeyValue<string, string>) {
        if (!this.projectsKeyValue) {
            this.projectsKeyValue = [];
        }
        this.projectsKeyValue.push(projectItem);
        this.projectsMenu.next(this.projectsKeyValue);
    }

    increment() {
        this.currentProjectIndex += 1;
    }

    decrement() {
        this.currentProjectIndex -= 1;
    }

    getNextId() {
        if (this.projectsIds && this.currentProjectIndex < this.projectsIds.length) {
            this.increment();
            return this.projectsIds[this.currentProjectIndex];
        }
    }

    getPrevId() {
        if (this.projectsIds && this.currentProjectIndex > 0) {
            this.decrement();
            return this.projectsIds[this.currentProjectIndex];
        }
    }
}
