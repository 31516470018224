import { Action } from '@ngrx/store';
import { Organization } from '../models/organization.model';
import { OrganizationField } from '../models/organization-field.model';
import { Project } from '../models/project.model';
import { ProjectField } from '../models/project-field.model';
import { VolunteerDetailsBoolean } from '../models/volunteer-details-booleans.model';
import { VolunteerDetailsOptions } from '../models/volunteer-details-options.model';
import { VolunteerDetailsText } from '../models/volunteer-details-text.model';
import { VolunteerDetailsNumeric } from '../models/volunteer-details-numeric.model';

export const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION';
export const FETCH_PROJECT = 'FETCH_PROJECT';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const SET_PROJECT = 'SET_PROJECT';
export const FETCH_ORGANIZATION_FIELDS = 'FETCH_ORGANIZATION_FIELDS';
export const FETCH_PROJECT_FIELDS = 'FETCH_PROJECT_FIELDS';
export const SET_ORGANIZATION_FIELDS = 'SET_ORGANIZATION_FIELDS';
export const SET_PROJECT_FIELDS = 'SET_PROJECT_FIELDS';
export const FETCH_VOLUNTEER_DETAILS_OPTIONS = 'FETCH_VOLUNTEER_DETAILS_OPTIONS';
export const FETCH_VOLUNTEER_DETAILS_TEXT = 'FETCH_VOLUNTEER_DETAILS_TEXT';
export const FETCH_VOLUNTEER_DETAILS_BOOLEANS = 'FETCH_VOLUNTEER_DETAILS_BOOLEANS';
export const FETCH_VOLUNTEER_DETAILS_NUMERIC = 'FETCH_VOLUNTEER_DETAILS_NUMERIC';
export const SET_VOLUNTEER_DETAILS_OPTIONS = 'SET_VOLUNTEER_DETAILS_OPTIONS';
export const SET_VOLUNTEER_DETAILS_TEXT = 'SET_VOLUNTEER_DETAILS_TEXT';
export const SET_VOLUNTEER_DETAILS_BOOLEANS = 'SET_VOLUNTEER_DETAILS_BOOLEANS';
export const SET_VOLUNTEER_DETAILS_NUMERIC = 'SET_VOLUNTEER_DETAILS_NUMERIC';
export const ADD_PROJECT_TO_ORGANIZATION = 'ADD_PROJECT_TO_ORGANIZATION';

export class FetchOrganization implements Action {
    readonly type = FETCH_ORGANIZATION;
}

export class FetchProject implements Action {
    readonly type = FETCH_PROJECT;

    constructor(public payload: string) { }
}

export class SetOrganization implements Action {
    readonly type = SET_ORGANIZATION;

    constructor(public payload: Organization) { }
}

export class SetProject implements Action {
    readonly type = SET_PROJECT;

    constructor(public payload: Project) { }
}

export class FetchOrganizationFields implements Action {
    readonly type = FETCH_ORGANIZATION_FIELDS;
}

export class SetOrganizationFields implements Action {
    readonly type = SET_ORGANIZATION_FIELDS;

    constructor(public payload: OrganizationField[]) { }
}

export class FetchProjectFields implements Action {
    readonly type = FETCH_PROJECT_FIELDS;
}

export class SetProjectFields implements Action {
    readonly type = SET_PROJECT_FIELDS;

    constructor(public payload: ProjectField[]) { }
}

export class FetchVolunteerDetailsBoolean implements Action {
    readonly type = FETCH_VOLUNTEER_DETAILS_BOOLEANS;
}

export class SetVolunteerDetailsBoolean implements Action {
    readonly type = SET_VOLUNTEER_DETAILS_BOOLEANS;

    constructor(public payload: VolunteerDetailsBoolean[]) { }
}

export class FetchVolunteerDetailsOption implements Action {
    readonly type = FETCH_VOLUNTEER_DETAILS_OPTIONS;
}

export class SetVolunteerDetailsOption implements Action {
    readonly type = SET_VOLUNTEER_DETAILS_OPTIONS;

    constructor(public payload: VolunteerDetailsOptions[]) { }
}

export class FetchVolunteerDetailsText implements Action {
    readonly type = FETCH_VOLUNTEER_DETAILS_TEXT;
}

export class SetVolunteerDetailsText implements Action {
    readonly type = SET_VOLUNTEER_DETAILS_TEXT;

    constructor(public payload: VolunteerDetailsText[]) { }
}

export class FetchVolunteerDetailsNumeric implements Action {
    readonly type = FETCH_VOLUNTEER_DETAILS_NUMERIC;
}

export class SetVolunteerDetailsNumeric implements Action {
    readonly type = SET_VOLUNTEER_DETAILS_NUMERIC;

    constructor(public payload: VolunteerDetailsNumeric[]) { }
}

export class AddProjectToOrganization implements Action {
    readonly type = ADD_PROJECT_TO_ORGANIZATION;

    constructor(public payload: Project) { }
}

export type AppActions = SetOrganization | SetProject | FetchOrganizationFields | FetchOrganization | FetchProject | SetOrganizationFields
    | FetchProjectFields | SetProjectFields | FetchVolunteerDetailsBoolean | SetVolunteerDetailsBoolean | FetchVolunteerDetailsOption | SetVolunteerDetailsOption
    | FetchVolunteerDetailsText | SetVolunteerDetailsText | FetchVolunteerDetailsNumeric | SetVolunteerDetailsNumeric | AddProjectToOrganization;
