import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.reducer';
import * as AppActions from '../store/app.actions';
import { take } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';
import { Organization } from '../models/organization.model';

@Injectable({ providedIn: 'root' })
export class OrganizationResolverService implements Resolve<Organization> {
    
    constructor(private store: Store<fromApp.FeatureState>, private actions$: Actions) {        
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.store.dispatch(new AppActions.FetchOrganization());
        return this.actions$.pipe(ofType(AppActions.SET_ORGANIZATION), take(1));
    }
}
