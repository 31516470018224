import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Observable } from "rxjs";
import { Organization } from "../models/organization.model";

@Injectable()
export class OrganizationService {
    url: string;

    constructor(private httpClient: HttpClient) {
        this.url = environment.organizationApiUrl;
    }

    put(organization: Organization): Observable<any> {
        return this.httpClient.post<any>(this.url + "OrganizationPublic/OrganizationUpdate", organization);
    }

    done(): Observable<any> {
        return this.httpClient.post<any>(this.url + "OrganizationPublic/OrganizationDoneUpdate", null);
    }
}