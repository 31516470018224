import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig } from '@angular/material/core';
import { 
  PerfectScrollbarModule, 
  PERFECT_SCROLLBAR_CONFIG, 
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';


import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
//import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';

import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { StoreModule } from '@ngrx/store';
import { appReducer } from './shared/store/app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { appEffects } from './shared/store/app.effects';
import { AuthInterceptorService } from './shared/services/auth/auth-interceptor.service';
import { organizationFieldsEffects } from './shared/store/organization-fields.effects';
import { CitiesService } from './shared/services/cities.service';
import { projectFieldsEffects } from './shared/store/project-fields.effects';
//import { volunteerDetailsBooleansEffects } from './shared/store/volunteer-details-booleans.effects';
import { volunteerDetailsOptionsEffects } from './shared/store/volunteer-details-options.effects';
import { ProjectService } from './shared/services/project.service';
import { OrganizationService } from './shared/services/organization.service';
import { volunteerDetailsBooleansEffects } from './shared/store/volunteer-details-booleans.effects';
import { volunteerDetailsTextEffects } from './shared/store/volunteer-details-text.effects';
import { volunteerDetailsNumericEffects } from './shared/store/volunteer-details-numeric.effects';
import { NavigationService } from "app/shared/services/navigation.service";


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    PerfectScrollbarModule,
        StoreModule.forRoot({ appState: appReducer }),
        EffectsModule.forRoot([appEffects, organizationFieldsEffects, projectFieldsEffects, volunteerDetailsOptionsEffects, volunteerDetailsBooleansEffects, volunteerDetailsTextEffects, volunteerDetailsNumericEffects]), //for now no volunteerDetailsBooleansEffects
    RouterModule.forRoot(rootRouterConfig, { useHash: false })
  ],
  declarations: [AppComponent],
    providers: [
        CitiesService,
        ProjectService,
        OrganizationService,
        NavigationService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
      { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }