import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment.prod';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
    })
};

@Injectable()
export class AuthGuard implements CanActivate {
  public authToken;
  private isAuthenticated = false; // Set this value dynamically

    private token: string;
    url: string;

    constructor(private httpClient: HttpClient, private router: Router) {
        this.url = environment.usersApiUrl;
    }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if (this.isAuthenticated || this.getToken()) {
          this.isAuthenticated = true;
      return true
    }
    this.router.navigate(['/sessions/signin']);
    return false;
  }

    signinUser(username: string, password: string) {
        var data = "grant_type=password&username=" + username + "&password=" + password;
        return this.httpClient.post<any>(this.url + "token", data, httpOptions);
    }

    logout() {
        this.token = null;
        this.isAuthenticated = false;
        localStorage.removeItem('token');
    }

    getToken() {
        if (!this.token) {
            this.token = localStorage.getItem('token');
        }
        return this.token;
    }

    setToken(token: string, isAuthenticated: boolean) {
        this.token = token;
        this.isAuthenticated = isAuthenticated;
        localStorage.setItem('token', token);
    }

}