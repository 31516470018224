import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Observable } from "rxjs";
import { Project } from "../models/project.model";

@Injectable()
export class ProjectService {
    url: string;

    constructor(private httpClient: HttpClient) {
        this.url = environment.organizationApiUrl;
    }

    put(project: Project): Observable<any> {
        return this.httpClient.post<any>(this.url + "ProjectPublic/ProjectUpdate", project);
    }

    post(project: Project): Observable<any> {
        return this.httpClient.post<any>(this.url + "ProjectPublic/Post", project);
    }
}