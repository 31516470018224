export class ContactType {
    public contactTypeID: number;
    public title: string;
    public sort: number;

    constructor(contactTypeID: number, title: string, sort: number) {
        this.contactTypeID = contactTypeID;
        this.title = title;
        this.sort = sort;
    }
}