import { Pipe, PipeTransform } from "@angular/core";
import { VolunteerDetailsOptions } from "../../shared/models/volunteer-details-options.model";

@Pipe({ name: 'volunteerDetailById', pure: false })
export class volunteerDetailByIdPipe implements PipeTransform {
    transform(VolunteerDetailsArray: any[], volunteerDetailsID: number, volunteerDetailsType: string) {
        let volunteerDetails = {};
        switch (volunteerDetailsType) {
            case 'options':
                volunteerDetails = VolunteerDetailsArray.filter(x => x.volunteerDetailsOptionsID == volunteerDetailsID)[0];
                break;
            case 'boolean':
                volunteerDetails = VolunteerDetailsArray.filter(x => x.volunteerDetailsBooleanID == volunteerDetailsID)[0];
                break;
            case 'text':
                volunteerDetails = VolunteerDetailsArray.filter(x => x.volunteerDetailsTextID == volunteerDetailsID)[0];
                break;
            case 'numeric':
                volunteerDetails = VolunteerDetailsArray.filter(x => x.volunteerDetailsNumericID == volunteerDetailsID)[0];
                break;
            default:
        }

        return volunteerDetails;
  }
}