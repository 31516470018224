import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class CitiesService {
    url: string;

    constructor(private httpClient: HttpClient) {
        this.url = environment.organizationApiUrl;
    }

    getCities(search: string) {
        return this.httpClient.get<any>(this.url + "OrganizationPublic/GetCities/" + search);
    }

    search(query: string): Observable<any> {
        return this.httpClient
            .get<any>(this.url + "OrganizationPublic/GetCities/" + query, {
                observe: 'response',
                //params: {
                //    q: query,
                //    sort: 'stars',
                //    order: 'desc'
                //}
            })
            .pipe(
                map(res => {
                    return res.body;
                })
            );
    }
}