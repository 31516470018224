import { Organization } from '../models/organization.model';
import { OrganizationField } from '../models/organization-field.model';
import * as Actions from './app.actions';
import { Project } from '../models/project.model';
import { ProjectField } from '../models/project-field.model';
import { VolunteerDetailsBoolean } from '../models/volunteer-details-booleans.model';
import { VolunteerDetailsOptions } from '../models/volunteer-details-options.model';
import { VolunteerDetailsText } from '../models/volunteer-details-text.model';
import { VolunteerDetailsNumeric } from '../models/volunteer-details-numeric.model';
import { ContactType } from '../models/contact-type.model';
import { act } from '@ngrx/effects';

export interface FeatureState {
    appState: State;
}

export interface State {
    organization: Organization;
    organizationFields: OrganizationField[];
    project: Project
    projectFields: ProjectField[];
    volunteerDetailsBoolean: VolunteerDetailsBoolean[];
    volunteerDetailsOptions: VolunteerDetailsOptions[];
    volunteerDetailsText: VolunteerDetailsText[];
    volunteerDetailsNumeric: VolunteerDetailsNumeric[];
    contactTypes: ContactType[];
}

const initialState: State = {
    organization: null,
    organizationFields: [],
    project: null,
    projectFields: [],
    volunteerDetailsBoolean:[],
    volunteerDetailsOptions: [],
    volunteerDetailsText: [],
    volunteerDetailsNumeric: [],
    contactTypes: [new ContactType(1, 'יו"ר', 1), new ContactType(2, 'מנכ"ל', 2), new ContactType(3, 'רכז התנדבות', 3), new ContactType(4, 'איש קשר', 4)
        , new ContactType(5, 'רכז פרויקט', 5), new ContactType(6, 'ראש צוות', 6), new ContactType(7, 'מנהל תפעול', 7)]
};

export function appReducer(state = initialState, action: Actions.AppActions) {
    switch (action.type) {
        case Actions.FETCH_ORGANIZATION:
            return {
                ...state
            };
        case Actions.SET_ORGANIZATION:
            const org = action.payload;
            return {
                ...state,
                organization :  org,
            };
        case Actions.FETCH_PROJECT:
            return {
                ...state
            };
        case Actions.SET_PROJECT:
            const proj = action.payload;
            return {
                ...state,
                project: proj,
            };
        case Actions.FETCH_ORGANIZATION_FIELDS:
            return {
                ...state,
            };
        case Actions.SET_ORGANIZATION_FIELDS:
            return {
                ...state,
                organizationFields : [...action.payload]
            };
        case Actions.FETCH_PROJECT_FIELDS:
            return {
                ...state,
            };
        case Actions.SET_PROJECT_FIELDS:
            return {
                ...state,
                projectFields: [...action.payload.filter(x => x.description != "company")]
            };
        case Actions.FETCH_VOLUNTEER_DETAILS_BOOLEANS:
            return {
                ...state,
            };
        case Actions.SET_VOLUNTEER_DETAILS_BOOLEANS:
            return {
                ...state,
                volunteerDetailsBoolean: [...action.payload]
            };
        case Actions.FETCH_VOLUNTEER_DETAILS_OPTIONS:
            return {
                ...state,
            };
        case Actions.SET_VOLUNTEER_DETAILS_OPTIONS:
            return {
                ...state,
                volunteerDetailsOptions: [...action.payload]
            };
        case Actions.FETCH_VOLUNTEER_DETAILS_TEXT:
            return {
                ...state,
            };
        case Actions.SET_VOLUNTEER_DETAILS_TEXT:
            return {
                ...state,
                volunteerDetailsText: [...action.payload]
            };
        case Actions.FETCH_VOLUNTEER_DETAILS_NUMERIC:
            return {
                ...state,
            };
        case Actions.SET_VOLUNTEER_DETAILS_NUMERIC:
            return {
                ...state,
                volunteerDetailsNumeric: [...action.payload]
            };
        case Actions.ADD_PROJECT_TO_ORGANIZATION:
            this.state.organization.projects = [...this.state.organization.projects, action.payload];
            return {
                ...state
            };            
        default:
            return state;
    }
}