import { Actions, Effect, ofType } from '@ngrx/effects';
import * as AppActions from './app.actions'    
import { switchMap, map, catchError } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { Organization } from '../models/organization.model';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Project } from '../models/project.model';

@Injectable()
export class appEffects {

    @Effect()
    fetchOrganization = this.actions$.pipe(
        ofType(AppActions.FETCH_ORGANIZATION),
        switchMap(() => {
            return this.httpClient.get<Organization>(environment.organizationApiUrl + "OrganizationPublic/get")
                // inner observable
                .pipe(map(resData => {
                    // hack - only hebrew projects
                    if (resData.projects) {
                        resData.projects = resData.projects.filter(x => x.languageID == 1 || x.languageID == 0);
                    }
                    return new AppActions.SetOrganization(resData);
                }), catchError(error => {
                    // error code here
                    return of(); // return new observable
                }));
        }),        
    );

    @Effect()
    fetchProject = this.actions$.pipe(
        ofType(AppActions.FETCH_PROJECT),
        switchMap((fetchProject: any) => {
            return this.httpClient.post<Project>(environment.organizationApiUrl + "ProjectPublic/ProjectByEncrypted?encryptedId=" + fetchProject.payload,'')
                // inner observable
                .pipe(map(resData => {
                    return new AppActions.SetProject(resData);
                }), catchError(error => {
                    // error code here
                    return of(); // return new observable
                }));
        }),
    );

    constructor(private actions$: Actions, private httpClient: HttpClient) { }
}