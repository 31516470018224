<div class="sidebar-panel">
    <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold">

            <div class="branding">
                &nbsp;
            </div>

            <div class="app-user">
                <h3 class="app-user-name mb-05">                    
                    רשימת פרויקטים
                </h3>
                <!-- Small buttons -->

            </div>

            <ul class="menu mr-05" *ngIf="!layoutConf.isMobile">
                <li style="margin-left:10px;border-bottom-style:groove;border-bottom-width:thin" *ngFor="let project of menuProjects; let j = index;">
                    <span [ngClass]="{'font-weight-bold':project.key == currentProjectId}">{{project.value}}</span>                       
                </li>
            </ul>
            <!-- Navigation -->

        </div>
    </div>
</div>