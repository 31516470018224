import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { OrganizationResolverService } from './shared/services/organization-resolver.service';

export const rootRouterConfig: Routes = [
  { 
    path: '', 
    redirectTo: 'organization/form', 
    pathMatch: 'full' 
  },
  
  {
    path: '', 
    component: AuthLayoutComponent,
    children: [
      { 
        path: 'sessions', 
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session'} 
      }
    ]
  },
  {
    path: '', 
    component: AdminLayoutComponent,
      canActivate: [AuthGuard],
      resolve: [OrganizationResolverService],
    children: [
      {
        path: 'organization', 
            loadChildren: () => import('./views/organization/organization.module').then(m => m.OrganizationModule), 
            data: { title: 'Organization', breadcrumb: 'Organization'}
        },
        {
        path: 'project',
            loadChildren: () => import('./views/project/project.module').then(m => m.ProjectModule),
            data: {title: 'Project', breadcrumb: 'Project' }
        }
    ]
  },
  { 
    path: '**', 
    redirectTo: 'sessions/404'
  }
];

