import { Actions, Effect, ofType } from '@ngrx/effects';
import * as AppActions from './app.actions'
import { switchMap, map } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { VolunteerDetailsOptions } from '../models/volunteer-details-options.model';

@Injectable()
export class volunteerDetailsOptionsEffects {
    @Effect()
    fetchVolunteerDetailsOptions = this.actions$.pipe(
        ofType(AppActions.FETCH_VOLUNTEER_DETAILS_OPTIONS),
        switchMap(() => {
            return this.httpClient.get<VolunteerDetailsOptions[]>(environment.organizationApiUrl + "ProjectPublic/GetVolunteerDetailsOptions");
        }),
        map(volunteerDetailsOptions => {
            return volunteerDetailsOptions.map(item => {
                var options = JSON.parse(item.options);
                var volunteerOption: VolunteerDetailsOptions = {
                    isMultiSelect: item.isMultiSelect,
                    options : item.options,
                    optionsArray: JSON.parse(item.options),
                    sort: item.sort,
                    title: item.title,
                    volunteerDetailsOptionsID : item.volunteerDetailsOptionsID
                }
                return volunteerOption;
            })
        }),
        map(volunteerDetailsOptions => {
            return new AppActions.SetVolunteerDetailsOption(volunteerDetailsOptions);
        })
    )

    constructor(private actions$: Actions, private httpClient: HttpClient) { }
}