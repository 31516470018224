import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { AuthGuard } from "../../services/auth/auth.guard";
import * as appReducer from '../../../shared/store/app.reducer';
import { Organization } from '../../../shared/models/organization.model';
import { Store } from '@ngrx/store';
import { Observable } from "rxjs/internal/Observable";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { KeyValue } from "@angular/common";

@Component({
    selector: "app-sidebar-side",
    templateUrl: "./sidebar-side.component.html"
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
    public menuProjects: KeyValue<string, string>[];
    public hasIconTypeMenuItem: boolean;
    public iconTypeMenuTitle: string;
    private menuItemsSub: Subscription;
    public layoutConf: ILayoutConf;
    public userFriendlyName: string;
    public organization: Organization;
    subscriptions: Subscription[];
    organizationState: Observable<appReducer.State>;
    currentProjectId: string;

    constructor(
        private navService: NavigationService,
        public themeService: ThemeService,
        private layout: LayoutService,
        private AuthService: AuthGuard,
        private store: Store<appReducer.FeatureState>,
        private route: ActivatedRoute
    ) { 
this.subscriptions = new Array<Subscription>();
}

    ngOnInit() {
        this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
        this.layoutConf = this.layout.layoutConf;

        // projects:
            this.organizationState = this.store.select('appState');
              this.subscriptions.push(this.organizationState.subscribe(
                data => {
                      this.organization = data.organization;
                      if (this.organization.projects && this.organization.projects.length > 0) {
                          this.navService.setProjectIds(this.organization.projects);
                          // only shows if no projects
                          this.navService.hideAddProjectMenuItem();
                      }                        
                }
              ));

        this.subscriptions.push(this.navService.currentProjectId$.subscribe(
            data => {
                this.currentProjectId = data;
            }
        ));

        this.subscriptions.push(this.navService.projectsMenu$.subscribe(menuItem => {
            this.menuProjects = menuItem;
        }));
    }

    
    ngAfterViewInit() { }
    ngOnDestroy() {
        if (this.menuItemsSub) {
            this.menuItemsSub.unsubscribe();
        }
        if (this.subscriptions && this.subscriptions.length > 0) {
            this.subscriptions.forEach(x => x.unsubscribe());
        }
    }
    toggleCollapse() {
        if (
            this.layoutConf.sidebarCompactToggle
        ) {
            this.layout.publishLayoutChange({
                sidebarCompactToggle: false
            });
        } else {
            this.layout.publishLayoutChange({
                // sidebarStyle: "compact",
                sidebarCompactToggle: true
            });
        }
    }

    updateCurrentProject(encryptedID: string) {
        // update navigation servivce
        this.navService.setCurrentIndexByProjectId(encryptedID);
    }
}
