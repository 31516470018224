import { Actions, Effect, ofType } from '@ngrx/effects';
import * as AppActions from './app.actions'
import { switchMap, map } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { VolunteerDetailsBoolean } from '../models/volunteer-details-booleans.model';

@Injectable()
export class volunteerDetailsBooleansEffects {
    @Effect()
    fetchVolunteerDetailsBooleans = this.actions$.pipe(
        ofType(AppActions.FETCH_VOLUNTEER_DETAILS_BOOLEANS),
        switchMap(() => {
            return this.httpClient.get<VolunteerDetailsBoolean[]>(environment.organizationApiUrl + "ProjectPublic/GetVolunteerDetailsBoolean");
        }),
        map(volunteerDetailsBoolean => {
            return volunteerDetailsBoolean.map(item => {
                return item;
            })
        }),
        map(volunteerDetailsBoolean => {
            return new AppActions.SetVolunteerDetailsBoolean(volunteerDetailsBoolean);
        })
    )

    constructor(private actions$: Actions, private httpClient: HttpClient) { }
}