import { Actions, Effect, ofType } from '@ngrx/effects';
import * as AppActions from './app.actions'
import { switchMap, map } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { VolunteerDetailsNumeric } from '../models/volunteer-details-Numeric.model';

@Injectable()
export class volunteerDetailsNumericEffects {
    @Effect()
    fetchVolunteerDetailsNumeric = this.actions$.pipe(
        ofType(AppActions.FETCH_VOLUNTEER_DETAILS_NUMERIC),
        switchMap(() => {
            return this.httpClient.get<VolunteerDetailsNumeric[]>(environment.organizationApiUrl + "ProjectPublic/GetVolunteerDetailsNumeric");
        }),
        map(volunteerDetailsNumeric => {
            return volunteerDetailsNumeric.map(item => {
                return item;
            })
        }),
        map(volunteerDetailsNumeric => {
            return new AppActions.SetVolunteerDetailsNumeric(volunteerDetailsNumeric);
        })
    )

    constructor(private actions$: Actions, private httpClient: HttpClient) { }
}