import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import 'rxjs/add/operator/do';
import { AuthGuard } from './auth.guard';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor{

    constructor(private authService: AuthGuard, private router: Router) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.getToken()) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.authService.getToken()}`
                }
            })
            return next.handle(req).do((event: HttpEvent<any>) => {
                
            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.authService.logout();
                        this.router.navigate(['sessions/signin']);
                    }
                }
            });
        }
        else {
            return next.handle(req).do((event: HttpEvent<any>) => {

            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.authService.logout();
                        this.router.navigate(['sessions/signin']);
                    }
                }
            });
        }

    }
}