import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));


/**
 * Only for the demo purpose
 */
//if (environment.production && document) {
//  const script = document.createElement('script');
//  script.innerHTML = `
//    !function(f,b,e,v,n,t,s)
//    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
//    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
//    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
//    n.queue=[];t=b.createElement(e);t.async=!0;
//    t.src=v;s=b.getElementsByTagName(e)[0];
//    s.parentNode.insertBefore(t,s)}(window, document,'script',
//    'https://connect.facebook.net/en_US/fbevents.js');
//    fbq('init', '2268386293237836');
//    fbq('track', 'PageView');`;
//  document.head.appendChild(script);
//}